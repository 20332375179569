.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-form {
  height: 90vh;
}

#login_ {
  /* position: absolute; */
  right: 5%;
  top: 5%;
  width: 318px; /*Easter Egg - E/17/318 is one of the developers of MedGenie*/
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

#home-login {
  position: absolute;

  background-color: white;
  width: 40px;
  height: 40px;
  border: none;
}

/* sidebar related */
.sidebar {
  /* position: absolute;
  left: -180px; */
  /* display: flex; */
  height: 100vh;
  width: 280px;
  transition: width 0.4s ease-out;

  /* background-color: #ffffff; */
}
.closed {
  width: 4.5rem;
}

.sidebar.closed span {
  display: none;
  transition: display 0.4s ease-out;
}

/*Search Box Related*/
.find-a-doctor {
  font-size: x-large;
  font-family: "roboto";
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
  padding: 15px;
  border-radius: 10px;
}

.find-a-doctor-input {
  font-size: 16px;
  border: 2px solid;
  border-radius: 4px;
  border-color: #ffffff;
  background-color: white;
  margin: 2px 0;
  width: 85%;
  color: grey;
}
.find-a-doctor-input:focus {
  outline: none;
}
.find-a-doctor-input-field {
  border: 1px solid;
  border-radius: 8px;
  border-color: #4285f4;
  margin: 2px 0;
  width: 100%;
  height: 38px;
}

.find-a-doctor-button {
  border: 1px solid;
  font-size: large;
  height: 36px;
  margin-top: 20px;
  border-radius: 6px;
  color: white;
  background-color: #4285f4;
  width: 292px;
  height: 38px;
}

/*Patient Home*/

/*Patient-Doctor Page*/

/*top-right-button related*/
#btn-1 {
  border-radius: 60px;
  background-color: #4285f4;
  width: 200px;
  position: fixed;
  top: 30px;
  /*left: 950px;*/
  right: 2%;
}

.find-a-doc-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modl-pdoc,
.overlay-pdoc {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  transition: ease-out;
}

.overlay-pdoc {
  background: rgba(49, 49, 49, 0.8);
}

.close-modl-pdoc {
  height: 2px;
  color: rgba(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border: none;
  position: absolute;
  top: 1%;
  /* left: 750px; */
  right: 1%;
}
